import { Typography } from '@material-ui/core';
import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

const CustomNode = memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div>
          <Typography noWrap>
              {data.label}
          </Typography>
          <Typography noWrap>
          {data.file}
          </Typography>
          <Typography noWrap>
          {data.description}
          </Typography>
      
      </div>
      <Handle
        type="source"
        position="bottom"
        id="b"
        // style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});

export default CustomNode