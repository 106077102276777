import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Switch>
        <Route path='/login' component={LoginPage}/>
        <Route path='/' component={MainPage}/>
      </Switch>
     </BrowserRouter>
    </div>
  );
}

export default App;
