import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import LogoGroup from '../../icons/GroupLogo';
import { Box, Collapse, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#1B73C2'
    },
    card: {
        width: 275,
        borderRadius: '24px',
        textAlign: 'center'
    },
    input: {
        width: '100%',
        margin: '0 0 10px 0'
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    collapse: {
        position: 'absolute',
        bottom: '40px',
    }
});

const LoginPage = () => {
    const hystory = useHistory()
    const [data, setData] = useState({
        login: '',
        password: ''
    })
    const [open, setOpen] = useState(false)

    const handleCahnge = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const handleSubmit = () => {
        const { login, password } = data
        if (password === '1234' && login === 'admin') {
            hystory.push('/partners')
            localStorage.setItem('user', 'admin')
        } else if (password === '0000' && login === 'user') {
            hystory.push('/partners')
            localStorage.setItem('user', 'user')
        } else {
            setData({
                login: '',
                password: ''
            })
            setOpen(true)
        }
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false)
            }, 5000)
        }
    }, [open])

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Collapse className={classes.collapse} in={open}>
                <Alert
                    severity="error"
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    Неверный логин или пароль
                </Alert>
            </Collapse>
            <Card className={classes.card} variant="outlined">
                <CardContent>
                    <LogoGroup color={'#1B73C2'} />
                </CardContent>
                <CardContent>
                    <TextField autoComplete='off' className={classes.input} name='login' label="Логин" variant="outlined" value={data.login} onChange={(e) => handleCahnge(e)} />
                    <TextField autoComplete='off' className={classes.input} name='password' label="Пароль" variant="outlined" value={data.password} onChange={(e) => handleCahnge(e)} />
                </CardContent>
                <CardContent>
                    <Box className={classes.box}>
                        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                            Войти
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

export default LoginPage