import React from 'react'

const EventsIcon = (props) => {
    const {color} = props
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.08744 5.40112L3.35472 5.53499C2.87257 5.57225 2.44316 5.82495 2.17652 6.22827L0.178212 9.25059C-0.0240135 9.55645 -0.0561618 9.93922 0.0921577 10.2745C0.240516 10.6098 0.545397 10.8435 0.9077 10.8996L2.49421 11.1453C2.86534 9.17583 3.75378 7.21115 5.08744 5.40112Z" fill={color || "white"}/>
<path d="M8.85474 17.5058L9.10048 19.0923C9.15661 19.4546 9.39028 19.7595 9.72555 19.9078C9.86504 19.9696 10.0127 20 10.1596 20C10.366 20 10.5709 19.9399 10.7495 19.8218L13.7719 17.8235C14.1753 17.5568 14.4279 17.1274 14.4651 16.6453L14.599 14.9126C12.7889 16.2463 10.8243 17.1347 8.85474 17.5058Z" fill={color || "white"}/>
<path d="M8.2579 16.4062C8.31298 16.4062 8.36837 16.4016 8.42365 16.3924C9.2488 16.2544 10.0441 16.022 10.8028 15.7178L4.28211 9.19714C3.97789 9.95577 3.74547 10.7511 3.6075 11.5763C3.55313 11.9015 3.66223 12.2329 3.89539 12.4661L7.53381 16.1045C7.7274 16.2981 7.98869 16.4062 8.2579 16.4062Z" fill={color || "white"}/>
<path d="M18.4053 8.86725C20.0009 5.78235 20.06 2.52237 19.9767 0.993471C19.9481 0.469607 19.5304 0.0519528 19.0065 0.0233983C18.7576 0.00980463 18.4626 0 18.13 0C16.4204 0 13.7153 0.258944 11.1328 1.59476C9.08035 2.65635 6.38907 4.99388 4.80127 8.06581C4.82002 8.08046 4.83834 8.09601 4.85561 8.11327L11.8868 15.1445C11.9041 15.1617 11.9196 15.18 11.9342 15.1988C15.0062 13.6109 17.3437 10.9197 18.4053 8.86725ZM11.6288 4.2281C12.771 3.08588 14.6296 3.08576 15.7719 4.2281C16.3253 4.78142 16.63 5.51716 16.63 6.29969C16.63 7.08222 16.3253 7.81796 15.7719 8.37128C15.2009 8.94233 14.4505 9.22792 13.7004 9.22799C12.95 9.22803 12.2 8.94249 11.6288 8.37128C11.0754 7.81796 10.7706 7.08222 10.7706 6.29969C10.7706 5.51716 11.0754 4.78142 11.6288 4.2281Z" fill={color || "white"}/>
<path d="M12.4575 7.54265C13.1428 8.22801 14.2581 8.22804 14.9434 7.54265C15.2754 7.21062 15.4583 6.76921 15.4583 6.29968C15.4583 5.83015 15.2754 5.38874 14.9434 5.05675C14.6008 4.71405 14.1506 4.54272 13.7004 4.54272C13.2503 4.54272 12.8002 4.71405 12.4575 5.05675C12.1255 5.38874 11.9426 5.83015 11.9426 6.29968C11.9426 6.76921 12.1255 7.21066 12.4575 7.54265Z" fill={color || "white"}/>
<path d="M0.597921 16.4819C0.747882 16.4819 0.897844 16.4247 1.01222 16.3102L2.92523 14.3972C3.15406 14.1684 3.15406 13.7974 2.92523 13.5686C2.69644 13.3398 2.32542 13.3398 2.0966 13.5686L0.183584 15.4816C-0.0452443 15.7104 -0.0452443 16.0814 0.183584 16.3102C0.297999 16.4247 0.44796 16.4819 0.597921 16.4819Z" fill={color || "white"}/>
<path d="M4.67819 15.3218C4.4494 15.0929 4.07839 15.0929 3.84956 15.3218L0.171621 18.9997C-0.0572071 19.2285 -0.0572071 19.5995 0.171621 19.8283C0.286036 19.9428 0.435997 19.9999 0.585958 19.9999C0.735919 19.9999 0.885881 19.9428 1.00026 19.8283L4.67815 16.1504C4.90702 15.9216 4.90702 15.5506 4.67819 15.3218Z" fill={color || "white"}/>
<path d="M5.60266 17.0749L3.68969 18.988C3.46086 19.2168 3.46086 19.5878 3.68969 19.8166C3.8041 19.931 3.95406 19.9882 4.10399 19.9882C4.25391 19.9882 4.40391 19.931 4.51828 19.8166L6.4313 17.9036C6.66012 17.6747 6.66012 17.3038 6.4313 17.0749C6.20251 16.8461 5.83149 16.8461 5.60266 17.0749Z" fill={color || "white"}/>
</svg>

    )
}

export default EventsIcon

