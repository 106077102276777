import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import axiosInstance from '../../api';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #FFF',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '444px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        width: '200px'
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
}));

const EventsPage = () => {
    const [selectedOrganizations, setSelectedOrganizations] = useState()
    const [organizations, setOrganizations] = React.useState([])
    const [events, setEvents] = useState([])
    const history = useHistory()
    const classes = useStyles();
    const handleClick = () => {
        history.push('/crate-event')
    }

    useEffect(() => {
        if(selectedOrganizations){
            axiosInstance.get(`Scenario/GetScenarios/${selectedOrganizations}`).then(res => setEvents(res.data))
        }
    }, [selectedOrganizations])

    useEffect(() => {
        axiosInstance.get('Organization/GetOrganizations').then(res => setOrganizations(res.data))
    }, [])

    const handleChangeOrganization = (event) => {
        setSelectedOrganizations(event.target.value)
      }

      const seeMore = (id) => {
        history.push(history.push(`/edit-event/${id}`))
      }

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', alignItems: 'center' }}>
            <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Организация</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOrganizations}
          onChange={handleChangeOrganization}
        >
          {organizations && organizations.map((item,index) => 
            <MenuItem key={index} value={item.organizationId}>{item.organizationName}</MenuItem>
          )}
        </Select>
      </FormControl>
                <Button startIcon={<AddCircleOutlineIcon />} variant="contained" style={{ background: '#1B73C2', color: 'white', borderRadius: '40px', height: '36px', marginLeft: '16px' }} onClick={() => handleClick()}>Новый</Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            {/* <TableCell>Кампания</TableCell> */}
                            <TableCell>Наименование</TableCell>
                            <TableCell align="right">Просмотр</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events && events.map((row) => (
                            <TableRow key={row.scenarioId}>
                                <TableCell style={{ width: '5%' }}>
                                    {row.scenarioId}
                                </TableCell>
                                {/* <TableCell component="th" scope="row">
                                    {row.scenarioName}
                                </TableCell> */}
                                <TableCell > {row.scenarioName}</TableCell>
                                <TableCell align="right"><Button onClick={() => seeMore(row.scenarioId)} color='primary' variant='outlined'>Подробнее</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default EventsPage