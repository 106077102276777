import React from 'react'

const PartnerIcon = (props) => {
    const {color} = props
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.14517 22.0441L8.81183 24.3775C8.97379 24.4583 9.15232 24.5004 9.33333 24.5004C9.51434 24.5004 9.69287 24.4583 9.85483 24.3775L14 22.3043L18.1452 24.3763C18.3067 24.4583 18.4853 24.5011 18.6665 24.5013C18.8477 24.5015 19.0264 24.4591 19.1882 24.3775L23.8548 22.0441C24.2503 21.8458 24.5 21.4421 24.5 21V15.1666C24.5 14.7245 24.2503 14.3208 23.8548 14.1225L19.8333 12.1123V6.99998C19.8333 6.55781 19.5837 6.15415 19.1882 5.95581L14.5215 3.62248C14.3594 3.54137 14.1807 3.49915 13.9994 3.49915C13.8182 3.49915 13.6394 3.54137 13.4773 3.62248L8.81067 5.95581C8.41633 6.15415 8.16667 6.55781 8.16667 6.99998V12.1123L4.14517 14.1236C3.95124 14.2203 3.78811 14.3691 3.67413 14.5533C3.56015 14.7376 3.49985 14.95 3.5 15.1666V21C3.5 21.4421 3.74967 21.8458 4.14517 22.0441ZM9.33333 14.1376L11.9747 15.4583L8.57617 17.1581L5.93483 15.8375L9.33333 14.1376ZM14 11.221L17.5 9.47098V12.1123L14 13.8623V11.221ZM21.308 15.4583L17.9457 17.1395L15.3043 15.8188L18.6667 14.1376L21.308 15.4583ZM9.33333 22.029L9.261 21.9928V19.425L12.8333 17.6376V20.279L9.33333 22.029ZM18.6667 22.029V19.3876L22.1667 17.6376V20.279L18.6667 22.029ZM14 5.97098L16.6413 7.29165L13.279 8.97281L10.6377 7.65215L14 5.97098Z" fill={color || '#1B73C2'}/>
</svg>

    )
}

export default PartnerIcon