import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from '../../api';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #FFF',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '444px'
    },
}));


const PartnerPage = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [organizations, setOrganizations] = useState([])
    const [data, setData] = useState({
        organizationName: ''
    })

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        axios.get('Organization/GetOrganizations').then(res => setOrganizations(res.data))
    }, [])

    const createOrganizacion = () => {
        axios.post('Organization/SetOrganization', data).then(res => {
            if (res.data.success) {
                setOrganizations([...organizations, { organizationId: organizations.length + 1, organizationName: data.organizationName }])
                setOpen(false);
            }
        }).catch(e => console.log(e))
    }

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Button startIcon={<AddCircleOutlineIcon />} variant="contained" style={{ background: '#1B73C2', color: 'white', borderRadius: '40px' }} onClick={() => handleOpen()}>Новый</Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Наименование</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizations && organizations.map((row) => (
                            <TableRow key={row.organizationId}>
                                <TableCell style={{ width: '5%' }}>
                                    {row.organizationId}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.organizationName}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2>Новый партнер</h2>
                        <TextField value={data.organizationName} onChange={(e) => setData({ organizationName: e.target.value })} style={{ width: '100%' }} autoComplete="off" label="Наименование" />
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '25px 0 0 0' }}>
                            <Button variant="outlined" style={{ borderRadius: '40px', width: '140px' }} onClick={() => handleClose()}>Отмена</Button>
                            <Button variant="contained" disabled={!data.organizationName} style={{ borderRadius: '40px', width: '140px', background: !data.organizationName ? '' : '#1B73C2' }} onClick={() => createOrganizacion()}>Создать</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default PartnerPage