import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, InputLabel, Select, makeStyles, Button } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import axios from '../../api'
import ReactFlow, { addEdge } from 'react-flow-renderer';
import CustomNode from './components/CustomNode';
import { useHistory } from 'react-router';
import axiosInstance from '../../api';

const nodeTypes = {
  selectorNode: CustomNode,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateEventPage = () => {
  const history = useHistory()
  const [selectedNodeId, setSelectedNodeId] = useState()
    const [data, setData] = useState({
      newNode: {
        phrases: '',
        voiceFile: '',
        description: ''
      },
      selectedNodeId: null,
      data: {
        centerX: 920,
        centerY: 240,
        scale: 1,
        nodes: [
          // {
          //   id: 2,
          //   x: -700,
          //   y: -69,
          //   type: 'some.mp4',
          //   label: 'test1',
          // }
        ],

        links: [
          // {
          //   id: 3,
          //   from: 2, // node id the link start
          //   to: 4,  // node id the link end
          // }
        ]
      },
    })
    const classes = useStyles();
    const [name, setName] = useState('')
    const [elements, setElements] = React.useState([]);
    const [organizations, setOrganizations] = React.useState([])
    const [selectedOrganizations, setSelectedOrganizations] = React.useState()
    const [incomingCall, setIncomingCall] = useState(false)
    const [phone, setPhone] = useState('')
    const [botName, setBotName] = useState('')
    useEffect(() => {
      axios.get('Organization/GetOrganizations').then(res => setOrganizations(res.data))
  }, [])

   

    const handleChange = (event) => {
      const {newNode} = data
        setData({
          ...data, newNode:{...newNode, [event.target.name]: event.target.value}
        })  
    };

    const handleChangeIncomingCall = (e) => {
      setIncomingCall(e.target.value)
    }

    const handleChangeOrganization = (event) => {
      setSelectedOrganizations(event.target.value)
    }

    // const elements = [
    //   { id: '1', data: { label: 'Node 1' }, position: { x: 250, y: 5 } },
    //   // you can also pass a React component as a label
    //   { id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
    //   { id: 'e1-2', source: '1', target: '2', animated: true },
    // ];

    const getNodeId = () => `${+new Date()}`

    const onConnect = (params) => setElements((els) => {
      data.data.links.push({
        id: getNodeId(),
       from: params.source,
       to: params.target
      })
      return addEdge(params, els)
      
    });

    const onAdd = useCallback(() => {
      const { phrases, voiceFile, description } = data.newNode
      if (phrases.trim().length && voiceFile.trim().length) {
        const newNode = {
          id: data.data.nodes && data.data.nodes.length ? ('el'+(data.data.nodes.length + 1)) : 'el'+1,
          type: 'selectorNode',
          data: {label: data.newNode.phrases, file: data.newNode.voiceFile, description: data.newNode.description},
          style: { border: '1px solid #777', padding: 10, maxWidth: '100px' },
          position: {
            x: (elements.length +10) + 100,
            y: (elements.length * 2) + 100,
          },
        };

        data.data.nodes.push({
          id: data.data.nodes && data.data.nodes.length ? ('el'+(data.data.nodes.length + 1)) : 'el'+1,
          x: (elements.length +10) + 100,
          y: (elements.length * 2) + 100,
          type: voiceFile,
          label: phrases,
          description: description
        })

        setData({
          ...data, newNode : {
            phrases: '',
            voiceFile: '',
            description: ''
          }
        })
        
        setElements((els) => els.concat(newNode));
      }
    }, [setElements, elements, data, setData]);

    const onElementClick = (event, element) => {
      setSelectedNodeId(element.id)
      setData({
        ...data,
        newNode:{
          phrases: element.data.label,
          voiceFile: element.data.file,
          description: element.data.description
        }
      })
    }

    const onSave = () => {
      const { phrases, voiceFile, description } = data.newNode
      if (phrases.trim().length && voiceFile.trim().length) {
        const index = data.data.nodes.findIndex(node => node.id === selectedNodeId)
        if (index > -1){
          const newElements = [...elements]
          newElements.splice(index, 1, {
            ...elements[index],
            data: {label: phrases, file: voiceFile, description: description},
          })
          setElements(newElements);

          data.data.nodes.splice(index, 1, {
            ...data.data.nodes[index],
            label: phrases,
            type: voiceFile,
            description: description
          })
          
          setSelectedNodeId(null)
          setData({
            ...data, newNode : {
              phrases: '',
              voiceFile: '',
              description:''
            }
          })
        }
      }
    }

    const onSubmit = (e)=> {
      e.preventDefault()
        const { nodes: nodesSource, links } = data.data
        const nodes = nodesSource.map(node => ({ ...node }))
        const rootNodes = []
        nodes.forEach(node => {
          if(links.every(link => link.to !== node.id)) rootNodes.push(node)
        })
  
        const getChildNodes = (childNodes) => {
          if (childNodes.length === 0) return
  
          return childNodes.map(childNode => {
            const childLinkToIds = links.reduce((resultLinks, link) => {
              if (link.from === childNode.id) resultLinks.push(link.to)
              
              return resultLinks
            }, [])
            const children = nodes.filter(node => childLinkToIds.includes(node.id))
  
            const node = {
              suitablePhrases: childNode.label.split(','),
              voiceFile: childNode.type,
              textMessage: childNode.description,
              description: '',
              x: childNode.x,
              y: childNode.y,
            }
            
            if (children.length) {
              node.childsMenu = getChildNodes(children)
            }
  
            return node
          })
        }
  
        axiosInstance
          .post('Scenario/SetScenario', {
            scenarioName: name,
            organizationId: selectedOrganizations,
            scenarioPhone: phone,
            incomingCall: Boolean(incomingCall),
            scenarioTelegramBot: botName,
            menu: getChildNodes(rootNodes)
          })
          .then(response  => {
            history.push(history.push(`/edit-event/${response.data.id}`))
          }).catch(e => console.log(e))
      

    }

    // const treeToFlowchart = (tree) => {
    //   const menuTree = tree.slice()
    //   const ids = new Set()
    //   const linkIds = new Set()

    //   const flatNodes = nodes => {
    //     let result = []

    //     nodes.forEach(node => {
    //       ids.add(node)
    //       const id = nextId('el')
    //       node.id = id

    //       result.push({
    //         id, 
    //         type: 'selectorNode',
    //         data:{
    //           label: node.suitablePhrases.join(', '), file: node.voiceFile, description: node.description
    //         },
    //         style: { border: '1px solid #777', padding: 10, maxWidth: '100px' },
    //         position: {
    //           x: node.x,
    //         y: node.y
    //         },
    //       }) 

    //       if (node.childsMenu) {
    //         result = result.concat(flatNodes(node.childsMenu))
    //       }
    //     })

    //     return result
    //   }

    //   const getNodeLinks = (nodes, parentId) => {
    //     let links = []

    //     nodes.forEach((node, index) => {
    //       linkIds.add(node.voiceFile + index)

    //       if (parentId) {
    //         links.push({
    //           id: nextId('el'),
    //           source: parentId,
    //           sourceHandle: "b",
    //           target: node.id,
    //           targetHandle: null
    //         })
    //       }

    //       if (node.childsMenu) {
    //         links = links.concat(getNodeLinks(node.childsMenu, node.id))
    //       }
    //     })

    //     return links
    //   }

    //   const nodes = flatNodes(menuTree)
    //   const links = getNodeLinks(menuTree)

    //   const newElements = [...nodes, ...links]
    //   setElements(newElements)

    //   return { nodes, links }
    // }

    const onNodeDragStop = (event, node) => {
        const index = data.data.nodes.findIndex(item => item.id === node.id )
        if (index > -1){
          const newElements = [...elements]
          newElements.splice(index, 1, {
            ...elements[index],
            position: node.position,
          })
          setElements(newElements);

          data.data.nodes.splice(index, 1, {
            ...data.data.nodes[index],
            x: node.position.x,
            y: node.position.y,
          })
        }
      
    };
  
    return (
        <div>
            <h1>Новая кампания</h1>
            <Box style={{width: '400px'}}>
                <TextField onChange={(e) => setName(e.target.value)} value={name} autoComplete='off' label="Наименование" style={{width: '100%', marginBottom: '14px'}}/>
                <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Организация</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOrganizations}
          onChange={handleChangeOrganization}
        >
          {organizations && organizations.map((item,index) => 
            <MenuItem key={index} value={item.organizationId}>{item.organizationName}</MenuItem>
          )}
        </Select>
      </FormControl>
            </Box>
            <RadioGroup style={{flexDirection: 'row', margin: '30px 0 30px 0'}} value={incomingCall} onChange={handleChangeIncomingCall}>
        <FormControlLabel value="false" style={{marginRight: '34px'}} control={<Radio color='primary' />} label="Исходящая компания" />
        <FormControlLabel value="true" control={<Radio color='primary' />} label="Входящая компания" />
      </RadioGroup>
      <TextField value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete='off' name='phone' label="Номер телефона" style={{width: '400px', marginBottom: '14px'}}/> 
      <TextField value={botName} onChange={(e) => setBotName(e.target.value)} autoComplete='off' name='botName' label="Название бота" style={{width: '400px', marginBottom: '14px'}}/>
      <div className="save__controls">
        <TextField value={data.newNode.phrases} onChange={(e) => handleChange(e)} autoComplete='off' name='phrases' label="Phrases" style={{width: '100%', marginBottom: '14px'}}/>
        <TextField value={data.newNode.voiceFile} onChange={(e) => handleChange(e)} autoComplete='off' name='voiceFile' label="Voice file" style={{width: '100%', marginBottom: '14px'}}/>
        <TextField
            style={{width: '100%', marginBottom: '14px'}}
          label="текст"
          name='description'
          multiline
          maxRows={3}
          value={data.newNode.description}
          onChange={(e) => handleChange(e)}
        />
          <Button color='primary' variant='contained' onClick={onAdd} style={{marginRight: '16px'}}>Add</Button>
          <Button color='primary' variant='contained' onClick={onSave}>Save node</Button>
        </div>
      <div style={{height: '50vh', width: '90%'}}>
         <ReactFlow onNodeDragStop={onNodeDragStop} nodeTypes={nodeTypes} onElementClick={onElementClick} elements={elements} onConnect={onConnect} />
      </div>
      <div className="save__controls">
          {/* <button onClick={onSave}>save</button>
          <button onClick={onRestore}>restore</button> */}
          <Button variant='outlined' onClick={(e) => history.goBack()} style={{marginRight: '16px'}}>Отмена</Button>
          <Button color='primary' variant='contained' onClick={(e) => onSubmit(e)}>Сохранить</Button>
        </div>
        </div>
    )
}

export default CreateEventPage