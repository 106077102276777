import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Route, Switch, useHistory } from 'react-router';
import PartnerIcon from '../../icons/PartnerIcon';
import LogoGroup from '../../icons/GroupLogo';
import EventsIcon from '../../icons/EventsIcon';
import PartnerPage from '../PartnerPage.js';
import EventsPage from '../EventsPage.js/index.';
import CreateEventPage from '../CreateEventPage';
import EditEventPage from '../CreateEventPage/EditEvent';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'white',
    // height: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      boxShadow: 'none'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#1B73C2',
    padding: '12px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
      color: 'white',
      background: '#1B73C2',
      borderRadius: '4px',
      cursor: 'pointer'
  },
  listItemActive: {
    color: '#1B73C2',
    background: 'white',
    borderRadius: '4px',
    cursor: 'pointer'
}
}));

function MainPage(props) {
    const hystory = useHistory()
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

const routes = [
    {
        title: 'Партнеры',
        href: '/partners'
    },
    {
        title: 'Кампании',
        href: '/events'
    }
]

    const changeRoute = (href) => {
        hystory.push(href)
    }

  const drawer = (
    <div>
       
      <div className={classes.toolbar} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
           <LogoGroup/>
      </div>
      <Divider />
      <List>
        {routes.map((item, index) => (
          <ListItem className={hystory.location.pathname === item.href ? classes.listItemActive : classes.listItem } key={item.title} onClick={() => changeRoute(item.href)}>
            <ListItemIcon>{index % 2 === 0 ? <PartnerIcon color={hystory.location.pathname === item.href  ? '' : 'white'} /> : <EventsIcon color={hystory.location.pathname === item.href  ? '#1B73C2' : 'white'} />}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const userRole = localStorage.getItem('user')

  if(!userRole){
    hystory.push('/login')
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{background: 'white'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
            <Route path='/partners' component={PartnerPage}/>
            <Route path='/events' component={EventsPage}/>
            <Route path='/crate-event' component={CreateEventPage}/>
            <Route path='/edit-event/:id' component={EditEventPage}/>
        </Switch>
      </main>
    </div>
  );
}

export default MainPage;