import axios from "axios"

const axiosInstance = axios.create({
    baseURL: 'https://solomon.onoi.kg/api/',
    headers: {
       apiKey: '0AC9D6EF-8B91-45E2-9941-F59264BF2808'
    }
  })


  
export default axiosInstance